import React from 'react';
import './App.css';
import AppHeader from './component/AppHeader.js';
import AppBody from './component/AppBody.js';
import AppFooter from './component/AppFooter.js';


class App extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchKey: '',
            masajidData: []
        }
    }

    onSearchKeyChange = (event) => {
        //this.setState({searchKey: data});
        let char = event.which || event.keyCode ;

        if(char === 13 || event.type === 'click'){
            this.getMasajidData();
            //console.log('Get Data...');
        }
        else if(event.target.value) {
            this.setState({searchKey : event.target.value });
            //console.log('Update Data...',  event.target.value);
        }
    };

    getMasajidData = () => {
        let searchKey = this.state.searchKey;
        if(searchKey && searchKey !== '') {
            let url = "http://www.ummahsoft.org/api/masjidi/v1/masjids?dist=50&searchKey=" + searchKey;
            fetch(url)
                .then((response)=> response.json() )
                .then((rawData)=> {
                        //console.log( rawData);
                        this.setState({masajidData : rawData });
                    }
                );
        }
    };


    render() {
        return (
            <div className="App">
                <AppHeader masajidDataSize={this.state.masajidData.length} onSearchKeyChange={this.onSearchKeyChange}/>
                <AppBody masajidData={this.state.masajidData}/>
                <AppFooter/>
            </div>
        );
    }
}

export default App;
