import React  from 'react';
import '../css/app-header.css';

class AppHeader extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchKey: ''
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        return false;
    }

    render() {
        const {onSearchKeyChange} = this.props;
        let isDataAvailable = this.props.masajidDataSize > 0;
        let classess = isDataAvailable ? ' data' : ' no-data';

        return (
            <div className={"app-header" + classess}>
                <div className="logo-container">
                    <img src="http://www.masjidiapp.com/admin/assets/img/logo/logo-114.png" alt='Masjidi Logo'/>&nbsp;
                    <span className="product-name"><b>Masjidi</b>Search</span>
                </div>
                <div className="action-container">
                    <input className="search" type="text" placeholder="Search Masjid (Zip, State or City) " onKeyUp={onSearchKeyChange}/>
                </div>
            </div>
        );
    }
}

export default AppHeader;